import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as styles from './HorizontalSlider.module.scss';

export const HorizontalSlider = ({ nodes, className, height, isDarkIndicatorMode = false, hideArrows = false }: { nodes: React.ReactNode[], className?: string | undefined, height?: number, isDarkIndicatorMode?: boolean, hideArrows?: boolean }) => {

    const nodeHeight = height ?? 576;

    const scrollRef = useRef<HTMLDivElement>(null);
    const intervalRef = useRef<NodeJS.Timer | null>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const scrollToIndex = (index: number) => {
        if (index === 0 && selectedIndex === nodes.length - 1) {
            scrollToIndex(nodes.length);
        } else if (index === nodes.length - 1 && selectedIndex === 0) {
            scrollToIndex(-1);
        } else {
            const target = scrollRef.current!;
            const scrollLeft = index * target.clientWidth + target.clientWidth;
            target.scrollLeft = scrollLeft;
        }
        intervalRef.current != null && clearInterval(intervalRef.current);
    }

    useEffect(() => {
        const refDiv = scrollRef.current;
        if (!refDiv) {
            return;
        }

        intervalRef.current = setInterval(() => {
            if (scrollRef.current && !scrollRef.current.matches(":hover")) {
                scrollRef.current.scrollLeft += scrollRef.current.clientWidth;
            }
        }, 5000);

        const updateIndex = (target: HTMLDivElement) => {
            const scrollWithoutFirst = target.scrollLeft - target.clientWidth;
            const index = Math.round(scrollWithoutFirst / target.clientWidth);
            setSelectedIndex((index + nodes.length) % nodes.length);
        };
        const wrapAround = (target: HTMLDivElement) => {
            const scrollWithoutFirst = target.scrollLeft - target.clientWidth;
            const fractionalIndex = scrollWithoutFirst / target.clientWidth;

            target.style.scrollBehavior = 'auto';
            if (fractionalIndex < -0.5) {
                target.scrollLeft += target.scrollWidth - target.clientWidth * 2;
            } else if (fractionalIndex > nodes.length - 0.5) {
                target.scrollLeft -= target.scrollWidth - target.clientWidth * 2;
            }
            target.style.scrollBehavior = 'smooth';
        };
        const fnScroll = (ev: Event) => {
            const target = ev.currentTarget as HTMLDivElement;
            updateIndex(target);
        }
        const fnScrollEnd = (ev: Event) => {
            const target = ev.currentTarget as HTMLDivElement;
            updateIndex(target);
            wrapAround(target);
        }
        
        refDiv.style.scrollBehavior = 'auto';
        refDiv.scrollLeft = refDiv.clientWidth;
        refDiv.style.scrollBehavior = 'smooth';

        refDiv.addEventListener('scroll', fnScroll, { passive: true });
        refDiv.addEventListener('scrollend', fnScrollEnd, { passive: true });
        return () => {
            refDiv.removeEventListener('scroll', fnScroll);
            refDiv.removeEventListener('scrollend', fnScrollEnd);
            intervalRef.current != null && clearInterval(intervalRef.current);
        }
    }, []);

    return <div className={cn(styles.carousel, className)}>
        <div className={styles.hiddenContainer} ref={scrollRef}>
            <div className={styles.carouselNode} style={{ maxHeight: nodeHeight + "px" }}>
                {nodes[nodes.length-1]}
            </div>
            {nodes.map((node, index) => (
                <div key={index} className={styles.carouselNode} style={{ maxHeight: nodeHeight + "px" }}>{node}</div>
            ))}
            <div className={styles.carouselNode} style={{ maxHeight: nodeHeight + "px" }}>
                {nodes[0]}
            </div>
        </div>
        {!hideArrows && 
        <>
            <button className={styles.leftButton} onClick={scrollToIndex.bind(null, selectedIndex - 1)} />
            <button className={styles.rightButton} onClick={scrollToIndex.bind(null, selectedIndex + 1)} />
        </>
        }

        <div className={styles.bottomButtons}>
            {nodes.map((_, index) => (
                <button
                    className={cn(styles.bottomButton, (selectedIndex === index) && styles.active, isDarkIndicatorMode && styles.darkIndicators)}
                    onClick={scrollToIndex.bind(null, index)}
                    key={index} />
            ))}
        </div>
    </div>
}