import * as styles from "./app.module.scss";
import { HorizontalSlider } from "ui";

function App() {
    const machinePdpData = window.app.preloadState.machinePdp;

    return <HorizontalSlider nodes={(machinePdpData.images as string[]).map(image => <img className={styles.carouselImage} src={image} />)} />
}

export default App;
